<template>
    <v-dialog max-width="600" v-model="dialog">
        <v-card>
            <v-toolbar dark>
                <v-card-title class="text-h5">
                    <span>{{ title }} State</span>
                    <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeDialog" dark icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row class="mt-2">
                        <v-col cols="12" md="12" sm="12">
                            <v-text-field
                                    :error="$v.state.name.$error"
                                    dense
                                    label="State Name*" outlined
                                    v-model="state.name">
                            </v-text-field>
                            <span class="text-danger" v-if="$v.state.name.$error">State Name is Required</span>
                            <span class="text-danger" v-if="errors.name">**{{errors.name[0]}}</span>
                        </v-col>
                        <v-col cols="12" md="12" sm="12">
                            <v-text-field
                                    :error="$v.state.prefix.$error"
                                    dense label="State Prefix*"
                                    outlined
                                    v-model="state.prefix">
                            </v-text-field>
                            <span class="text-danger" v-if="errors.prefix">**{{errors.prefix[0]}}</span>
                            <span class="text-danger" v-if="$v.state.prefix.$error">State Prefix is Required</span>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-autocomplete
                                    :error="$v.state.prefix.$error"
                                    :items="countries"
                                    color="blue-grey lighten-2"
                                    dense filled
                                    item-text="title" item-value="id"
                                    label="Select Country"
                                    outlined
                                    v-model="state.country_id">

                            </v-autocomplete>

                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="btn btn-primary"
                    :loading="loading"
                    @click="createOrUpdate()"
                >
                    Save
                </v-btn>
                <v-btn
                    text
                    @click="closeDialog"
                >
                    Cancel
                </v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>

</template>
<script>
    import StateService from "@/services/country/state/StateService";
    import CountryService from "@/services/country/CountryService";
    import {required} from "vuelidate/lib/validators";

    const country = new CountryService();

    const state = new StateService();
    export default {
        validations: {
            state: {
                name: {required},
                prefix: {required},

            },
        },
        data() {
            return {
                title: 'Create',
                dialog: false,
                edit: false,
                loading: false,
                countries: [],
                errors: [],
                state: {
                    name: '',
                    prefix: '',
                    country_id: null,
                },
            }
        },
        methods: {
            getCountries() {
                country
                    .getAllCountry()
                    .then((response) => {

                        this.countries = response.data.data;
                    })
                    .catch((error) => {
                        // console.log(error);
                    })
            },
            closeDialog() {
                this.dialog = false;
                this.errors = [];
                this.resetForm();
                this.$v.$reset()
            },
            openDialog() {
                this.dialog = true;
            },
            resetForm() {
                this.state = {
                    name: '',
                    prefix: '',
                };
                this.state.country_id = this.countryId;
            },
            createState() {
                this.resetForm();
                this.openDialog();
                this.edit = false;
                this.title = "Create";
            },
            editState(item) {
                this.openDialog();
                this.edit = true;
                this.state = item;
                this.title = "Edit";
            },
            createOrUpdate() {
                this.$v.$touch()
                if (this.$v.$error) {
                    setTimeout(() => {
                        this.$v.$reset()
                    }, 3000);
                } else {
                    if (!this.edit) this._create();
                    else this.update();
                }
            },
            _create: function () {
                this.loading = true;
                state
                    .create(this.state)
                    .then(response => {
                        this.$snotify.success("State created successfully");
                        this.closeDialog();
                        this.resetForm();
                        this.errors = [];
                        this.loading = false;
                        this.$emit('refresh');
                    })
                    .catch(error => {
                        this.errors = error.errors;
                    })
            },
            update: function () {
                this.loading = true;
                state
                    .update(this.state.id, this.state)
                    .then(response => {
                        this.$snotify.success("State updated successfully");
                        this.closeDialog();
                        this.resetForm();
                        this.errors = [];
                        this.loading = false;
                        this.$emit('refresh');
                    })
                    .catch(error => {
                        this.errors = error.errors;
                    })
            },

            stateDetail() {
                if (this.stateId != null || this.stateId != undefined) {
                    state
                        .show(this.stateId)
                        .then(response => {

                            this.state = response.data.state;

                        })
                }
            }
        },
        mounted() {
            this.getCountries();
            this.stateDetail();
            // console.log(this.countryId)
            if (this.countryId)
                this.state.country_id = this.countryId;
                this.state.name = this.countryId;

            // console.log(this.state)

        },
        computed: {
            stateId() {
                return this.$route.params.stateId;
            },
            countryId() {
                return parseInt(this.$route.params.country_id);
            },
            titleText() {
                if (this.stateId != null || this.stateId != undefined)
                    return this.title = "Edit";
                else
                    return this.title = "Create New"
            }
        }
    }
</script>