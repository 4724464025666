<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>States</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ States
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn  @click="createState()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add New
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                  <v-col cols="12" sm="6" md="4">
                <v-text-field label="Lookup by Name, Short Name" v-on:keyup.enter="searchState" clearable v-model="search.info" outlined dense></v-text-field>
              </v-col>
                  <v-col cols="12" sm="6" md="2">
                <v-btn :loading="isLoading"  @click="searchState"
                    class="mt-1 btn btn-primary"
                >
                  <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                  Search
                </v-btn>
              </v-col>
              </div>
            </div>

            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3" :class="sortedClass('name')"  @click="sortBy('name')"><strong>Name</strong></th>
                  <th><strong>Country Name</strong></th>
                  <th class="pr-3 text-center"><strong>ACTION</strong></th>
                </tr>
                </thead>
                <tbody>
                <template>

                  <tr v-for="(item, index) in sortedItems" :key="index">
                    <td class="px-2">
                      <a @click="editState(item)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {{item.name }}
                      </a>
                    </td>

                    <td class="px-2">
                      <a  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{item.country}}</a>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="manageCity(item)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="orange darken-2">fas fa-edit</v-icon>
                                </span>
                                <span class="navi-text">Manage City</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editState(item)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="orange darken-2">fas fa-edit</v-icon>
                                </span>
                                <span class="navi-text">Edit State</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a  @click="deleteState(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="red">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="sortedItems.length == 0">
                    <td colspan="4" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="sortedItems.length > 0"
                  class="pull-right mt-7"
                  @input="getAllState"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number  :disabled="isLoading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-or-update ref="create-or-update" @refresh="getAllState"></create-or-update>
    </div>
  </v-app>
</template>
<script>
import StateService from "@/services/country/state/StateService";
const state = new StateService();
import CreateOrUpdate from "./CreateOrUpdate";
export default {
  components:{
    CreateOrUpdate,
  },
  data() {
    return{
      isLoading:false,
      search:{
        info:'',
      },
      states:[],
      total: null,
      perPage: null,
      page: null,
      sort: {
        key: '',
        isAsc: false
      },
      status: [
        {name: 'Status', value: ''},
        {name: 'Active', value: 'active'},
        {name: 'Inactive', value: 'in_active'},
      ],

    }
  },
  methods: {
    getAllState(){
      this.isLoading=true;
      state
          .all(this.countryId)
          .then(response => {
            this.states=response.data.data;
            this.isLoading=false;
          })
          .catch((err) => {
            // console.log(err);
          });
    },
    searchState(){
      this.getAllState();
    },
    sortedClass (key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc' }` : '';
    },
    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    createOrUpdateState(stateId){
      if(stateId==null)
        this.$router.push({
          name:"state-create"
        });
      else
        this.$router.push({
          name:'state-edit',
          params:{stateId:stateId}
        })
    },
    createState(){
      this.$refs['create-or-update'].createState();
    },
    editState(item){
      this.$refs['create-or-update'].editState(item);
    },
    deleteState(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            state
                .delete(item)
                .then((response) => {
                  this.getAllState();
                  this.$snotify.success("State Info Sucessfully Deleted");
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },

    manageCity(item) {
      this.$router.push({
        name: 'city',
        params: {
          'country_id': this.countryId,
          'state_id': item.id
        }
      })
    },
  },
  mounted() {
    this.getAllState();
  },
  computed:{
    countryId () {
      return this.$route.params.country_id;
    },
    sortedItems () {
      const list = this.states.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }

      return list;
    }
  },
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>